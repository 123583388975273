<template>
  <section id="my-courses">
    <CourseContainer/>
  </section>
</template>

<script>
import CourseContainer from '../../components/user/course/Container.vue'

export default {
  components: {
    CourseContainer
  },
  data: () => ({
    
  })
}
</script>