<template>
  <section>
    <v-sheet 
      :class="$vuetify.breakpoint.mobile?'':'custom-border border pa-5'" 
      class="" 
      v-if="!loading"
      :color="$vuetify.breakpoint.mobile?'transparent':''"
    >
      <div class="d-flex justify-end mb-3" v-if="courses.length>0">
        <v-btn 
          text 
          small
          @click="gallery =  true"
        >
          <v-icon color="secondary-2" size="22">
            mdi-view-gallery-outline
          </v-icon>
        </v-btn>
        <v-btn 
          text 
          small
          @click="gallery = false"
        >
          <v-icon color="secondary-2" size="22">
            mdi-format-list-bulleted
          </v-icon>
        </v-btn>
      </div>
      <div v-if="courses.length>0" :class="gallery && 'coursebox'">
        <CourseCard 
          v-for="item in courses" 
          :key="item.id" 
          :data="item" 
          :progress="getProgress(item)"
          :gallery="gallery"/>
      </div>
      <v-sheet
        v-else
        color="transparent"
        class="d-flex flex-column align-center justify-center pb-10"
        height="500"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300" 
            :src="require('../../../assets/default/empty_drafts.png')" />
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Courses</h3>
      </v-sheet>
    </v-sheet>
    <circular v-else/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CourseCard from './Card.vue'

export default {
  components: {
    CourseCard
  },
  setup() {
  },
  data: () => ({
    loading: true,
    gallery: true,
  }),
  computed: {
    ...mapState('usr', {
      courses: (state) => state.courses
    }),
  },

  mounted() {
    this.getCoursesAction().then(() => {
      this.loading=false
    })
  },

  methods: {
    ...mapActions('usr', ['getCoursesAction']),

    getProgress(item){
      let progress = Math.round(((item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics) / (item.assessments_count + item.topics_count + item.sub_topics_count)) * 100)
      return progress ? progress : 0
    }
  }
}
</script>
